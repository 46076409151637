import moment from "moment";

export interface Payer {
  name: string;
  ic_no: string;
  email: string;
  phone: string;
  amount: number;
  address: string;
  poskod: string;
  waqafType: any;
  year: any;
}

export const getAgencyCode = () => {
  const url = new URL(document.location.toString());
  let agency = url.searchParams.get("agency");
  return agency == null ? "" : agency;
};

export const generateLink = (agency: string, payer: Payer) => {
  const url = "https://prod.snapnpay.co/payments/mobile/checkout/";

  const payerData = extra_data(payer, agency);
  let ref = getReference(agency, payer);

  console.log("ref", ref);
  console.log("payerData", payerData);
  if (payerData.length > 0) {
    ref += `~${payerData}`;
  }

  //   let refNo = `${ref}~${payerData}`;

  const path = `${url}${ref}/${payer.amount}/${agency}/${payer.email}`;

  if (path === "") {
    alert("Sila pastikan maklumat yang diisikan adalah lengkap");
  } else {
    document.location.href = path;
  }
};

export const getReference = (agency: string, payer: Payer) => {
  if (
    agency === "WK-00002" ||
    agency === "WK-00027" ||
    agency === "WK-20001" ||
    agency === "WK-20002" ||
    agency === "WK-20003" ||
    agency === "WK-20004" ||
    agency === "WK-20005" ||
    agency === "WK-20006" ||
    agency === "WK-20007" ||
    agency === "WK-20008" ||
    agency === "WK-20009" ||
    agency === "WK-20010" ||
    agency === "WK-20011" ||
    agency === "WK-20012" ||
    agency === "WK-20013" ||
    agency === "WK-20014" ||
    agency === "WK-20015" ||
    agency === "WK-20016" ||
    agency === "WK-20017" ||
    agency === "WK-20018" ||
    agency === "WK-20019" ||
    agency === "WK-20020" ||
    agency === "WK-20021" ||
    agency === "WK-20022" ||
    agency === "WK-20023" ||
    agency === "WK-20024" ||
    agency === "WK-20025" ||
    agency === "WK-20026" ||
    agency === "WK-20027" ||
    agency === "WK-20028" ||
    agency === "WK-20029" ||
    agency === "WK-20030" ||
    agency === "WK-20031" ||
    agency === "WK-20032" ||
    agency === "WK-20033" ||
    agency === "WK-20034" ||
    agency === "WK-20035" ||
    agency === "WK-20036" ||
    agency === "WK-20037" ||
    agency === "WK-20038" ||
    agency === "WK-20039" ||
    agency === "WK-20040" ||
    agency === "WK-20041" ||
    agency === "WK-20042" ||
    agency === "WK-20043" ||
    agency === "WK-20044" ||
    agency === "WK-20045"
  ) {
    return payer.ic_no;
  } else {
    return moment().format("YYYYMMDDHHmmssSSS");
  }
};

export const extra_data = (payer: Payer, agency: string): string => {
  if (agency === "WK-00001") {
    return (
      encodeURIComponent(payer.ic_no || "") +
      "~" +
      encodeURIComponent(payer.waqafType || "") +
      "~" +
      encodeURIComponent(payer.name || "") +
      "~" +
      encodeURIComponent(payer.phone || "") +
      "~" +
      encodeURIComponent(payer.address || "") +
      "~" +
      encodeURIComponent(payer.poskod || "") +
      "~" +
      encodeURIComponent(payer.year || "")
    );
  } else if (
    agency === "WK-00002" ||
    agency === "WK-20001" ||
    agency === "WK-20002" ||
    agency === "WK-20003" ||
    agency === "WK-20004" ||
    agency === "WK-20005" ||
    agency === "WK-20006" ||
    agency === "WK-20007" ||
    agency === "WK-20008" ||
    agency === "WK-20009" ||
    agency === "WK-20010" ||
    agency === "WK-20011" ||
    agency === "WK-20012" ||
    agency === "WK-20013" ||
    agency === "WK-20014" ||
    agency === "WK-20015" ||
    agency === "WK-20016" ||
    agency === "WK-20017" ||
    agency === "WK-20018" ||
    agency === "WK-20019" ||
    agency === "WK-20020" ||
    agency === "WK-20021" ||
    agency === "WK-20022" ||
    agency === "WK-20023" ||
    agency === "WK-20024" ||
    agency === "WK-20025" ||
    agency === "WK-20026" ||
    agency === "WK-20027" ||
    agency === "WK-20028" ||
    agency === "WK-20029" ||
    agency === "WK-20030" ||
    agency === "WK-20031" ||
    agency === "WK-20032" ||
    agency === "WK-20033" ||
    agency === "WK-20034" ||
    agency === "WK-20035" ||
    agency === "WK-20036" ||
    agency === "WK-20037" ||
    agency === "WK-20038" ||
    agency === "WK-20039" ||
    agency === "WK-20040" ||
    agency === "WK-20041" ||
    agency === "WK-20042" ||
    agency === "WK-20043" ||
    agency === "WK-20044" ||
    agency === "WK-20045"
  ) {
    return (
      encodeURIComponent(payer.name || "") +
      "~" +
      encodeURIComponent(payer.email || "") +
      "~" +
      encodeURIComponent(payer.phone || "")
    );
  } else if (agency === "WK-00009") {
    return (
      encodeURIComponent(payer.ic_no || "") +
      "~" +
      encodeURIComponent(payer.waqafType || "") +
      "~" +
      encodeURIComponent(payer.name || "") +
      "~" +
      encodeURIComponent(payer.phone || "") +
      "~" +
      encodeURIComponent(payer.address || "") +
      "~" +
      encodeURIComponent(payer.poskod || "") +
      "~" +
      encodeURIComponent(payer.year || "")
    );
  } else if (agency === "WK-00027") {
    return (
      encodeURIComponent(payer.ic_no || "") +
      "~" +
      encodeURIComponent(payer.name || "") +
      "~" +
      encodeURIComponent(payer.email || "") +
      "~" +
      encodeURIComponent(payer.phone || "") +
      "~" +
      encodeURIComponent(payer.address || "") +
      "~" +
      encodeURIComponent(payer.waqafType || "")
    );
  } else {
    return "";
  }
};

export enum Parameters {
  Email,
  ICNo,
  Name,
  Address,
  Poskod,
  Phone,
  WaqafType,
  WaqafGeneral,
  Year,
  Amount,
}

export const getFields = (agency: string) => {
  if (
    agency === "WK-00002" ||
    agency === "WK-20001" ||
    agency === "WK-20002" ||
    agency === "WK-20003" ||
    agency === "WK-20004" ||
    agency === "WK-20005" ||
    agency === "WK-20006" ||
    agency === "WK-20007" ||
    agency === "WK-20008" ||
    agency === "WK-20009" ||
    agency === "WK-20010" ||
    agency === "WK-20011" ||
    agency === "WK-20012" ||
    agency === "WK-20013" ||
    agency === "WK-20014" ||
    agency === "WK-20015" ||
    agency === "WK-20016" ||
    agency === "WK-20017" ||
    agency === "WK-20018" ||
    agency === "WK-20019" ||
    agency === "WK-20020" ||
    agency === "WK-20021" ||
    agency === "WK-20022" ||
    agency === "WK-20023" ||
    agency === "WK-20024" ||
    agency === "WK-20025" ||
    agency === "WK-20026" ||
    agency === "WK-20027" ||
    agency === "WK-20028" ||
    agency === "WK-20029" ||
    agency === "WK-20030" ||
    agency === "WK-20031" ||
    agency === "WK-20032" ||
    agency === "WK-20033" ||
    agency === "WK-20034" ||
    agency === "WK-20035" ||
    agency === "WK-20036" ||
    agency === "WK-20037" ||
    agency === "WK-20038" ||
    agency === "WK-20039" ||
    agency === "WK-20040" ||
    agency === "WK-20041" ||
    agency === "WK-20042" ||
    agency === "WK-20043" ||
    agency === "WK-20044" ||
    agency === "WK-20045"
  ) {
    return [
      Parameters.Email,
      Parameters.ICNo,
      Parameters.Name,
      Parameters.Phone,
      Parameters.Amount,
    ];
  } else if (agency === "WK-00027") {
    return [
      Parameters.Email,
      Parameters.ICNo,
      Parameters.Name,
      Parameters.Phone,
      Parameters.Address,
      Parameters.Amount,
      Parameters.WaqafGeneral,
      Parameters.WaqafType,
    ];
  } else if (agency === "WK-00001") {
    return [
      Parameters.Email,
      Parameters.ICNo,
      Parameters.Name,
      Parameters.Phone,
      Parameters.Address,
      Parameters.Poskod,
      Parameters.Amount,
      Parameters.WaqafType,
    ];
  } else if (agency === "WK-00009") {
    return [
      Parameters.Email,
      Parameters.ICNo,
      Parameters.Name,
      Parameters.Phone,
      Parameters.Address,
      Parameters.Poskod,
      Parameters.Year,
      Parameters.Amount,
      Parameters.WaqafType,
    ];
  } else {
    return [Parameters.Amount];
  }
};

export const hasParameters = (field: any, parameters: Parameters[]) => {
  for (let i = 0; i < parameters.length; i++) {
    if (parameters[i] === field) {
      console.log("hre");
      return true;
    }
  }

  return false;
};

export const getWakafList = (agency: string) => {
  if (agency === "WK-00009") {
    return [{ id: "00", name: "Waqaf Tunai" }];
  } else if (agency === "WK-00001") {
    return [
      { id: "01", name: "Wakaf Perumahan" },
      { id: "02", name: "Wakaf Masjid (pembinaan,penambaikan,pembesaran)" },
      { id: "03", name: "Wakaf Pendidikan" },
      { id: "04", name: "Wakaf Al Quran" },
      { id: "05", name: "Wakaf Pengajian Tinggi KITAB" },
      { id: "06", name: "Wakaf Kitab Buku Agama" },
      { id: "07", name: "Wakaf Kesihatan" },
      { id: "08", name: "Wakaf As Siyanah (Penyelenggaraan Aset/Harta Waqaf)" },
      { id: "9", name: "Wakaf Al Khadamat (Perkhidmatan / Servis)" },
      {
        id: "10",
        name:
          "Al Intifa' Al Aradhi Al Mauqufah Al Mu'attalah (Membangunkan Tanah / Aset Waqaf)",
      },
    ];
  }
  if (agency === "WK-00027") {
    return [
      { id: "85102", name: "WAKAF AM" },
      { id: "85211", name: "(WAKAF KHAS) Pembangunan masjid" },
      { id: "85202", name: "(WAKAF KHAS) Peralatan perubatan klinik/hospital" },
      { id: "85208", name: "(WAKAF KHAS) Pembangunan rumah warga emas" },
      { id: "85209", name: "(WAKAF KHAS) Pembangunan Rumah anak-anak yatim" },
      { id: "85212", name: "(WAKAF KHAS) Pembangunan pusat tahfiz" },
    ];
  } else {
    return [];
  }
};

export const getYear = () => {
  let year: any = [];
  for (let i = 0; i < 5; i++) {
    year.push(new Date().getFullYear() - i);
  }

  return year;
};
