import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import UserProfileDropdown from "./UserProfileDropDown";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import EmailIcon from "@material-ui/icons/Email";
import InputAdornment from "@material-ui/core/InputAdornment";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import MapIcon from "@material-ui/icons/Map";
import ExploreIcon from "@material-ui/icons/Explore";
import {
  getAgencyCode,
  generateLink,
  Payer,
  Parameters,
  hasParameters,
  getWakafList,
  getYear,
} from "../utils";

const pbmains_wakaf_types = [
  {
    id: "am",
    name: "Wakaf Am",
  },
  { id: "khas", name: "Wakaf Khas" },
];

const useStyles = makeStyles((theme) => ({
  mainroot: {
    padding: "20px !important",
  },
  containerCenter: {
    justifyContent: "center",
    paddingTop: 20,
    "@media(minWidth: 780px)": {},
  },

  cardContent: {
    justifyContent: "center",
    "@media(minWidth: 780px)": {},
  },
  typography: {
    justifyContent: "center",
    alignItems: "center",
    justifyItems: "center",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    textAlign: "center",
  },
  gridRoot: {
    minHeight: "380px",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
  root: {
    border: "1px solid #00ff00",

    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
    flexDirection: "column",
    display: "flex",
    "@media(minWidth: 780px)": {
      flexDirection: "column",
      alignItems: "center",
      // display: ""
    },
  },
  media: {
    paddingTop: "56.25%",
    backgroundSize: "contain",
  },
  inputsearch: {
    flex: 1,
    width: "100%",
    "@media(minWidth: 780px)": {
      width: "100%",
    },
  },
  dropdown: {
    padding: 20,
    // '@media(minWidth: 780px)': {
    //   flexDirection: "row-reverse"
    // }
  },
  appBarSpacer: theme.mixins.toolbar,
  cardFlow: {
    justify: "center",
    alignItems: "stretch",
    "@media(minWidth: 780px)": {
      justifyContent: "flex-end",
      alignItems: "flex-start",
      flexDirection: "row-reverse",
    },
  },
  item: {
    margin: theme.spacing(1),
    "@media(minWidth:780px)": {},
  },
  flex: {
    flex: 1,
  },
  lefticon: {
    marginLeft: theme.spacing(4),
  },
  containerButton: {
    justifyContent: "center",
    paddingTop: 20,
    "@media(minWidth: 780px)": {},
  },
  buttons: {
    margin: theme.spacing(1),
  },
  image: {
    width: "100%",
    height: "100%",
  },
}));

const Form = ({ user, signOut, parameters }: any) => {
  const classes = useStyles();
  const [payer, setPayer] = useState<Payer>({
    name: "",
    ic_no: "",
    email: user,
    phone: "",
    amount: 0,
    address: "",
    poskod: "",
    waqafType: "0",
    year: String(new Date().getFullYear()),
  });

  React.useEffect(() => {
    console.log("parameters", parameters);
  }, []);

  const handleSubmit = (
    evt: React.MouseEvent<HTMLButtonElement | MouseEvent>
  ) => {
    let agency = getAgencyCode();

    console.log("payer", payer.waqafType);
    if (hasParameters(Parameters.ICNo, parameters)) {
      if (payer.ic_no.length !== 12) {
        alert("Sila isi dan pastikan nombor kad pengenalan anda adalah betul.");
        return;
      }
    }

    if (hasParameters(Parameters.Name, parameters)) {
      if (payer.name.length < 1) {
        alert("Sila isi dan pastikan nama anda adalah betul.");
        return;
      }
    }

    if (hasParameters(Parameters.Phone, parameters)) {
      if (payer.phone.length < 10) {
        alert("Sila isi dan pastikan nombor telefon anda adalah betul.");
        return;
      }
    }

    if (hasParameters(Parameters.Address, parameters)) {
      if (payer.address.length < 1) {
        alert("Sila isi dan pastikan alamat anda adalah betul.");
        return;
      }
    }

    if (hasParameters(Parameters.Poskod, parameters)) {
      if (payer.poskod.length !== 5) {
        alert("Sila isi dan pastikan poskod anda adalah betul.");
        return;
      }
    }

    if (hasParameters(Parameters.WaqafType, parameters)) {
      if (payer.waqafType === "0") {
        alert("Sila pilih dan pastikan jenis wakaf adalah betul.");
        return;
      }
    }

    if (hasParameters(Parameters.Year, parameters)) {
      if (payer.year.length === "0") {
        alert("Sila pilih dan pastikan tahun wakaf adalah betul.");
        return;
      }
    }

    if (payer.amount < 1) {
      alert("Sila isi dan pastikan jumlah wakaf adalah betul.");
      return;
    }

    generateLink(agency, payer);
  };

  const updateField = (key: string, value: string) => {
    setPayer({ ...payer, [key]: value });
  };

  const checkStringOnly = (
    field: string,
    min: number,
    max: number,
    input: string
  ) => {
    if (input.length < min) {
      return new Error(`Sila pastikan ruang ${field} diisi`);
    }

    if (input.length > max) {
      return new Error(`Sila pastikan ${field} dalam ${max} huruf sahaja`);
    }

    return null;
  };

  const checkNumberOnly = (field: string, input: string) => {
    if (/\D+/.test(input)) {
      return new Error(`Sila pastikan ${field} dalam nombor sahaja`);
    }

    return null;
  };

  const validateStringIC = (input: string) => {
    const err = checkStringOnly("no kp", 12, 12, input);

    if (err !== null) {
      return true;
    }

    return false;
  };

  const validateStringName = (input: string) => {
    const err = checkStringOnly("no kp", 1, 200, input);
    if (err !== null) {
      return true;
    }

    return false;
  };

  const validateStringPhone = (input: string) => {
    let err = checkStringOnly("no telefon", 10, 10, input);
    if (err !== null) {
      return true;
    }

    err = checkNumberOnly("no telefon", input);
    if (err !== null) {
      return true;
    }

    return false;
  };

  const validateAmount = (input: any) => {
    const err = checkNumberOnly("jumlah", input);
    if (err !== null) {
      return true;
    }

    if (payer.amount < 1) {
      return true;
    }

    return false;
  };

  const updateWaqafType = (evt: React.ChangeEvent<{ value: string }>) => {
    const value = evt.target.value;
    setPayer({ ...payer, waqafType: String(value) });
  };

  const updateWaqafGeneral = (evt: React.ChangeEvent<{ value: string }>) => {
    const value = evt.target.value;
    setPayer({ ...payer, waqafGeneral: String(value) });
  };

  const updateYear = (evt: React.ChangeEvent<{ value: string }>) => {
    const value = evt.target.value;
    setPayer({ ...payer, year: String(value) });
  };

  const logo = `https://assets.snapnpay.my/images/${getAgencyCode()}.jpg`;

  return (
    <div>
      <CssBaseline />
      {user.length > 0 ? (
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" noWrap>
              Tulus Wakaf
            </Typography>
            <div className={classes.flex} />
            <div className={classes.lefticon}>
              <UserProfileDropdown user={user} signOut={signOut} />
            </div>
          </Toolbar>
        </AppBar>
      ) : null}

      <main>
        <Container maxWidth="lg">
          {user.length > 0 ? <div className={classes.appBarSpacer} /> : null}
          <Container>
            <Grid container spacing={2} className={classes.containerCenter}>
              <Grid
                item
                xs={12}
                sm={3}
                style={{ marginTop: "10%", marginBottom: "15%" }}
              >
                <img src={logo} className={classes.image} />
                {/* <Typography style={{ textAlign: "center", marginTop: "5%" }}>
                  Perbadanan Wakaf Selangor
                </Typography> */}
              </Grid>
            </Grid>

            <Grid container spacing={6} justify="center">
              {/* {hasParameters(Parameters.WaqafGeneral, parameters) && (
                <Grid item xs={12}>
                  <InputLabel shrink style={{ width: "100%" }}>
                    Sila Pilih Jenis Waqaf
                  </InputLabel>

                  <Select
                    value={payer.waqafType}
                    style={{ width: "100%" }}
                    onChange={updateWaqafType}
                    displayEmpty
                  >
                    <MenuItem value="0">
                      <em>Jenis Wakaf</em>
                    </MenuItem>

                    {pbmains_wakaf_types.map((item, i) => (
                      <MenuItem value={item.id}>
                        <em>{item.name}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )} */}

              {hasParameters(Parameters.WaqafType, parameters) && (
                <Grid item xs={12}>
                  <InputLabel shrink style={{ width: "100%" }}>
                    Sila Pilih Jenis Waqaf
                  </InputLabel>

                  <Select
                    value={payer.waqafType}
                    style={{ width: "100%" }}
                    onChange={updateWaqafType}
                    displayEmpty
                  >
                    <MenuItem value="0">
                      <em>Jenis Wakaf</em>
                    </MenuItem>

                    {getWakafList(getAgencyCode()).map((item, i) => (
                      <MenuItem value={item.id}>
                        <em>{item.name}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {hasParameters(Parameters.Year, parameters) && (
                <Grid item xs={12}>
                  <InputLabel shrink style={{ width: "100%" }}>
                    Sila Pilih Tahun
                  </InputLabel>

                  <Select
                    value={payer.year}
                    style={{ width: "100%" }}
                    onChange={updateYear}
                    displayEmpty
                  >
                    {/* <MenuItem value="0">
                      <em>Tahun Wakaf</em>
                    </MenuItem> */}

                    {getYear().map((item, i) => (
                      <MenuItem value={item}>
                        <em>{item}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              {hasParameters(Parameters.Email, parameters) && (
                <Grid item xs={12}>
                  <TextField
                    disabled
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    autoComplete="true"
                    placeholder="Email"
                    value={payer.email}
                    className={classes.inputsearch}
                    inputProps={{ "aria-label": "Cari" }}
                    onChange={(e) => updateField("email", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              {hasParameters(Parameters.ICNo, parameters) && (
                <Grid item xs={12}>
                  <TextField
                    required
                    error={validateStringIC(payer.ic_no)}
                    type="number"
                    id="outlined-basic"
                    label="No Kad Pengenalan"
                    variant="outlined"
                    autoComplete="true"
                    placeholder="000000000000"
                    value={payer.ic_no}
                    inputProps={{ "aria-label": "Cari" }}
                    className={classes.inputsearch}
                    onChange={(e) => updateField("ic_no", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FingerprintIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              {hasParameters(Parameters.Name, parameters) && (
                <Grid item xs={12}>
                  <TextField
                    required
                    error={validateStringName(payer.name)}
                    type="text"
                    id="outlined-basic"
                    label="Nama Penuh"
                    variant="outlined"
                    autoComplete="true"
                    placeholder="Abu Bakar"
                    value={payer.name}
                    inputProps={{ "aria-label": "Cari" }}
                    className={classes.inputsearch}
                    onChange={(e) => updateField("name", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              {hasParameters(Parameters.Phone, parameters) && (
                <Grid item xs={12}>
                  <TextField
                    required
                    type={"number"}
                    error={validateStringPhone(payer.phone)}
                    id="outlined-basic"
                    label="No. Telefon Bimbit"
                    variant="outlined"
                    autoComplete="true"
                    placeholder="0123456789"
                    value={payer.phone}
                    inputProps={{ "aria-label": "Cari" }}
                    className={classes.inputsearch}
                    onChange={(e) => updateField("phone", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIphoneIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              {hasParameters(Parameters.Address, parameters) && (
                <Grid item xs={12}>
                  <TextField
                    required
                    type={"text"}
                    error={validateStringName(
                      payer.address ? payer.address : ""
                    )}
                    id="outlined-basic"
                    label="Alamat"
                    variant="outlined"
                    autoComplete="true"
                    placeholder="No 4, Jalan Ali"
                    value={payer.address}
                    inputProps={{ "aria-label": "Cari" }}
                    className={classes.inputsearch}
                    onChange={(e) => updateField("address", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MapIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              {hasParameters(Parameters.Poskod, parameters) && (
                <Grid item xs={12}>
                  <TextField
                    required
                    type={"number"}
                    error={validateStringName(payer.poskod ? payer.poskod : "")}
                    id="outlined-basic"
                    label="Poskod"
                    variant="outlined"
                    autoComplete="true"
                    placeholder="00000"
                    value={payer.poskod}
                    inputProps={{ "aria-label": "Cari" }}
                    className={classes.inputsearch}
                    onChange={(e) => updateField("poskod", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ExploreIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              {hasParameters(Parameters.Amount, parameters) && (
                <Grid item xs={12}>
                  <TextField
                    required
                    type={"number"}
                    error={validateAmount(payer.amount)}
                    id="outlined-basic"
                    label="Jumlah"
                    variant="outlined"
                    autoComplete="true"
                    placeholder="0"
                    value={payer.amount}
                    inputProps={{ "aria-label": "Cari" }}
                    className={classes.inputsearch}
                    onChange={(e) => updateField("amount", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MonetizationOnIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Button
                  style={{ width: "100%" }}
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  BAYAR
                </Button>
              </Grid>
            </Grid>
          </Container>

          <div className={classes.appBarSpacer} />
        </Container>
      </main>
    </div>
  );
};

export default Form;
