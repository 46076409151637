export const firebaseConfig = {
  apiKey: "AIzaSyARsXGADXa4OzvoPadu3OQ3wmKxsvGGfqc",
  authDomain: "tulus-my.firebaseapp.com",
  projectId: "tulus-my",
  storageBucket: "tulus-my.appspot.com",
  messagingSenderId: "522114229720",
  appId: "1:522114229720:web:39f34084f3298836e57ead",
  measurementId: "G-25DXG1XB99",
};
export const appinfo = {
  name: "SnapNPay i-Sedekah",
};
export const imageUri = `https://assets.tulus.my/images/`;
