import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import withFirebaseAuth, {
  WrappedComponentProps,
} from "react-with-firebase-auth";
import Login from "./components/Login";
import Form from "./components/Form";
import ErrorPage from "./components/ErrorPage";
import { firebaseConfig } from "./config";
import { getFields, getAgencyCode } from "./utils";
import {
  Route,
  BrowserRouter as Router,
} from "react-router-dom";

const App = ({ signInWithGoogle, signOut, user }: WrappedComponentProps) => {
  const url = new URL(document.location.toString());

  console.log("url", url);
  if (!url.search.includes("?agency=WK")) {
    return <ErrorPage />;
  }

  const agency = getAgencyCode();

  if (agency == "WK-00023") {
    const url = new URL(document.location.toString());
    let ctxid = url.searchParams.get("ctxid");
    ctxid = (ctxid == null ? "" : ctxid);

    return (<Router>
      <Route exact path="/" render={() => (window.location = "https://app.tulus.my/waqaf/AWQAF_Holdings_Berhad?ctxid="+ctxid)} />
    </Router>);
  }

  if (user) {
    return (
      <Form
        signOut={signOut}
        user={user.email}
        parameters={getFields(agency)}
      />
    );
  }

  return <Login signInWithGoogle={signInWithGoogle} />;
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAppAuth = firebaseApp.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
