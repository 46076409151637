import React from "react";
import * as firebase from "firebase/app";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import ArrowDropDownSharpIcon from "@material-ui/icons/ArrowDropDownSharp";
import UserIcon from "@material-ui/icons/AccountCircleOutlined";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  color: {
    color: "white",
  },
  icon: {
    color: "white",
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  user: firebase.User;
  signOut: () => void;
}

const UserProfileDropdown = ({ user, signOut }) => {
  const classes = useStyle();
  // const { state, dispatch } = useStore();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} id="MenuButton">
        <UserIcon className={classes.icon} />
        <p className={classes.color}>{user}</p>
        <ArrowDropDownSharpIcon className={classes.color} />
      </Button>
      <Paper>
        <Menu
          elevation={0}
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuList>
            <MenuItem onClick={signOut}>
              <Typography variant="button">Log Keluar</Typography>
            </MenuItem>
          </MenuList>
        </Menu>
      </Paper>
    </>
  );
};

export default UserProfileDropdown;
